<template>
  <section class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>数据中台</el-breadcrumb-item>
            <el-breadcrumb-item>储蓄卡</el-breadcrumb-item>
            <el-breadcrumb-item>充值设置</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
        <el-row class="search-box">
          <el-row class="search-row">
            <el-button type="primary" @click="protocolShow = true">用户协议</el-button>
            <el-button type="primary" @click="addData">创建</el-button>
            <el-button type="primary" @click="switchShow = true">储值卡开关</el-button>
          </el-row>
        </el-row>
        <el-row class="table-box">
          <el-table
                  v-loading="loading"
                  :data="tableData"
                  border fit
                  style="width: 100%"
                  :stripe="true"
                  :header-cell-style="{background: '#f5f8f9'}">
            <el-table-column width="60" prop="sortNo" label="序号">
              <template slot-scope="scope">
                <span>{{(page-1) * limit + scope.$index +1}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="payAmount" label="充值金额" width="180"></el-table-column>
            <el-table-column prop="donateAmount" label="赠送金额" width="180">
              <template slot-scope="scope">
                <span>{{scope.row.donateAmount || 0}}</span>
              </template>
            </el-table-column>
            <el-table-column label="附赠商品" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-for="(item,idx) in scope.row.depositCardGifts" :key="idx">{{item.giftName  + " "}}</span>
              </template>
            </el-table-column>
            <el-table-column label="类型" width="120">
              <template slot-scope="scope">
                {{scope.row.type | cardTypeFilter }}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="200"></el-table-column>
            <el-table-column label="操作" width="280">
              <template slot-scope="scope">
                <el-button v-if="page === 1 && scope.$index !== 0" type="text" @click="moveData(scope.row, 'top')">上移</el-button>
                <el-button v-if="(page-1) * limit + scope.$index !== total - 1" type="text" @click="moveData(scope.row, 'down')">下移</el-button>
                <el-button type="text" @click="editData(scope.row)">编辑</el-button>
                <el-button type="text" @click="delData(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-row>

      <!-- 添加充值规则 -->
      <el-dialog :title="dialogType" :visible.sync="dialogShow" width="550px">
        <el-form class="form-dialog-box" ref="paySet" :model="paySetData" :rules="rules">
          <el-form-item label="充值金额"  prop="payAmount">
            <el-input class="width-220" oninput="value=value.splice(0,5)" type="number" min="0" max="99999" placeholder="请输入充值金额" v-model="paySetData.payAmount"></el-input>
          </el-form-item>
          <el-form-item label="赠送金额" prop="donateAmount">
            <el-input class="width-220" type="number" min="0" max="99999" placeholder="请输入赠送金额" v-model="paySetData.donateAmount"></el-input>
          </el-form-item>
          <el-form-item label="附赠商品">
            <el-button class="bg-gradient" type="primary" size="mini" @click="selectCoupon">管理</el-button>
          </el-form-item>
          <el-row>
            <el-row>
              <el-col :span="16">优惠券名称</el-col>
              <el-col :span="8">数量</el-col>
            </el-row>
            <el-row class="p-top-10" v-for="(item,index) in paySetData.depositCardGifts" :key="index">
              <el-col :span="16">{{item.giftName || "暂无信息"}}</el-col>
              <el-col :span="8">{{item.giftNum}}</el-col>
            </el-row>
          </el-row>
          <el-divider></el-divider>
          <el-form-item label="充值类型">
            <el-radio v-model="paySetData.type" label="DAILY">日常</el-radio>
            <el-radio v-model="paySetData.type" label="OPEN_CARD">开卡</el-radio>
          </el-form-item>
        </el-form>
        <span slot="footer">
          <el-button  @click="dialogShow = false">取 消</el-button>
          <el-button :disabled="disable" class="bg-gradient" type="primary" @click="savePaySet">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 优惠券 -->
      <el-dialog title="选择赠送优惠券" width="450px" :visible.sync="couponShow">
        <el-table :data="couponData"
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          border fit
          style="width: 100%"
          :header-cell-style="{background: '#f5f8f9'}"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="giftName" label="券名称"></el-table-column>
          <el-table-column prop="giftNum" label="数量">
            <template slot-scope="scope">
              <el-input @change="(e)=>{judegGiftNum(e,scope.row)}" type="number" min="1" v-model="scope.row.giftNum"></el-input>
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer">
          <el-button @click="couponShow = false">取 消</el-button>
          <el-button class="bg-gradient" type="primary" @click="couponShow = false">确 定</el-button>
        </span>
      </el-dialog>
    <!-- 储值卡开关 -->
    <el-dialog  :visible.sync="switchShow" width="500px"  align="center" title="储值卡开关"  >
      <template >
        <el-switch
            v-model="isDepositcard"
            @change="changeswitch"
            active-color="#13ce66"
            inactive-color="#dedede"
            active-value="YES"
            inactive-value="NO">
        </el-switch>
      </template>
      <p>默认关闭，如果有用户充值（保留有储值卡余额），则无法关闭</p>
      <span slot="footer">
          <el-button type="plan" @click="switchShow = false">取消</el-button>
          <el-button class="bg-gradient" type="primary" @click="switchShow = false">确定</el-button>
        </span>
    </el-dialog>
      <!-- 添加用户协议 -->
      <el-dialog style="z-index:1111" :visible.sync="protocolShow" width="450px" title="用户协议" :modal="true">
        <el-input show-word-limit maxlength="500" rows="15" type="textarea" v-model="content"></el-input>
        <span slot="footer">
          <el-button type="plan" @click="protocolShow = false">取消</el-button>
          <el-button class="bg-gradient" type="primary" @click="saveProtocol">确定</el-button>
        </span>
      </el-dialog>
    </section>
</template>
<script>
import { mapState } from 'vuex';
import { urlObj } from '@/api/interface'
import { depositCard } from "@/api/interface/data";
import { coupon } from '@/api/interface/business'
import {eBooking} from "@/api/interface/eBooking";
export default {
  computed: {
    ...mapState(['hotelInfo'])
  },
  data(){
    let amountCheck = (rule, value, callback)=>{
      if(!value || value <= 0){
        this.disable = true
        return callback(new Error("请输入不小于等于0的充值金额"))
      }
      if(value > 99999){
        this.paySetData.payAmount = 99999
        this.disable = true
        return callback(new Error("金额不能大于99999"))
      }
      this.disable = false
    }
    let giftAmountCheck = (rule, value, callback)=>{
      if(value && value < 0 ){
        this.disable = true
        return callback(new Error("请输入大于等于0的金额"))
      }
      if(value > 99999){
        this.disable = true
        return callback(new Error("金额不能大于99999"))
      }
      this.disable = false
    }
    return{
      hotelId:"", // 酒店Id
      tableData:[], // 表格数据
      loading:false, // 表格加载状态
      total:0, //
      limit:10, //
      page:1,
      isDepositcard:'NO',
      switchShow:false,
      dialogType:"创建", // 创建/修改弹窗标题
      dialogShow:false, // 是否显示创建/修改充值设置弹窗
      paySetData:{ // 储值卡设置基础数据
        payAmount:"",
        donateAmount:"",
        type:"DAILY",
        sortNo:"",
        depositCardGifts:[]
      },
      rules:{
        payAmount: [
          { validator: amountCheck, trigger: 'change' }
        ],
        donateAmount: [
          { validator: giftAmountCheck, trigger: 'change' }
        ]
      },
      disable:true,
      couponShow:false, // 是否显示优惠券列表
      couponData:[], // 优惠券列表

      selectCouponData:[], // 选中的优惠券下标
      protocolShow:false, // 用户协议
      content:"" // 协议内容
    }
  },
  mounted(){
    this.getCardSetList();
    this.getCouponData();
    this.getProtocol()
  },
  methods:{
    getCardSetList(){ // 获取列表数据
      let url = depositCard.getCardSetList,
          param = {
            // hotelId:this.hotelInfo.id,
            companyId:this.hotelInfo.storeId,
            page:this.page,
            limit:this.limit
          };
      this.loading = true;
      this.$axios.get(url, param).then(res => {
        this.loading = false;
        if(res.success){
          this.tableData = res.records
          this.total = res.total
        }
      }).catch( err => {
        this.loading = false;
      })
    },

    //改变开关
    changeswitch(){
      let url = eBooking.getMemberBalance,
          param = {
            companyId:this.hotelInfo.storeId
          };
      this.$axios.post(url, param,'json').then(res => {
        if(res.success){
          if (this.isDepositcard == 'YES') this.saveProtocol()
          if (res.records.length != 0 && this.isDepositcard == 'NO'){
            this.$message.error('还有用户储值卡尚有余额，暂不能关闭')
            this.isDepositcard = 'YES'
          }
          if (res.records.length == 0 && this.isDepositcard == 'NO')this.saveProtocol()
        }
      }).catch( err => {
        this.loading = false;
      })

    },
    addData(){ //添加充值规则
      if(this.total >= 10){
        this.$message.warning("充值规则最多创建10个，请删除不需要的规则后重试");
        return;
      }
      this.originPaySetData()
      this.dialogType = "创建";
      this.dialogShow = this.disable = true;
    },

    editData(row){ // 编辑充值规则
      let { id, payAmount, sortNo, type, depositCardGifts = [], donateAmount} = {...row}
      this.selectCouponData = []
      depositCardGifts.forEach( item => {
        this.selectCouponData.push(item.giftId)
      })
      this.dialogType = "编辑";
      this.disable = false;
      this.dialogShow = true;
      this.paySetData = {
        id, payAmount, sortNo, type, depositCardGifts, donateAmount
      };
    },

    savePaySet(){ // 保存充值设置
      let param = this.paySetData, url;
      param.hotelId = this.hotelInfo.id;
      param.companyId = this.hotelInfo.storeId;
      param.sortNo = param.sortNo || this.total + 1;
      if(param.id){
        url = depositCard.editCardSet;
      }else{
        url = depositCard.addCardSet;
      }
      this.$axios.post(url, param, "json", {"Content-Type":"application/json; charset=UTF-8"}).then( res => {
        console.log(param)
        this.dialogShow = false
        if(res.code === "SUCCESS"){
          this.$message.success("添加成功")
          this.getCardSetList()
        }else{
          this.$message.warning("处理失败")
        }
        this.originPaySetData()
      })
    },

    moveData(row,type){ // 调整充值规则排序
      let param = {
        id:row.id,
        step:type === "down" ? 1 : 0
      },
      url = depositCard.moveCardSet
      this.$axios.post(url, param).then( res => {
        if(res.success){
          this.getCardSetList()
        }else{
          this.$message.warning("调整顺序失败")
        }
      })
    },

    delData(row){ // 删除
      let url = depositCard.delCardSet + `/${row.id}`;
      this.$confirm('是否删除当前充值规则?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then( confirm => {
          this.loading = true;
          this.$axios.post(url).then(res => {
            this.loading = false;
            if(res.success){
              this.$message.success("删除成功");
              this.getCardSetList();
            }
          })
        }).catch( err => {
          console.log("取消删除")
        })
    },

    pageChange(val){ // 修改每页显示条数
      this.limit = val
      this.getCardSetList()
    },

    handlePaging(val){ // 修改页码数据
      this.page = val;
      this.getCardSetList()
    },

    handleSelectionChange(val){ // 选中优惠券列表
      this.paySetData.depositCardGifts = val
    },

    getCouponData(){ // 获取优惠券列表
      let url = coupon.couponPage + "?limit=50&page=1" ,
          param = {
            condition: "",
            status: "PROCESSING",
            type: "",
            // hotelId:this.hotelInfo.id,
            companyId:this.hotelInfo.storeId,
            thresholdType:"GIFT"
          };
      this.$axios.post(url, param, "json").then( res => {
        if(res.success && res.records){
          res.records.filter( item => {
            return item.status === "PROCESSING" && (item.issueCount - item.couponCollection) > 0
          })
          this.couponData = res.records.map((item,index) => {
            item = {
              giftId:item.id,
              giftName:item.mainTitle,
              giftNum:1,
              residue:item.issueCount - item.couponCollection
            }
            return item
          })
        }
      })

    },

    selectCoupon(){ // 选择优惠券
      this.couponShow = true;
      this.$nextTick(()=>{ // 选中已有的优惠券
        this.paySetData.depositCardGifts.forEach( ( row ) => {
          this.couponData.forEach( ( item,index ) => {
            if(item.giftId === row.giftId){ // 优惠券Id相互匹配则选中
              this.$refs.multipleTable.toggleRowSelection(this.couponData[index],true);
            }
          })
        });
      })
    },

    judegGiftNum(e,val){ // 优惠券数量不能为0 或 空
      // createOperator
      // issueCount
      if(!e || e <= 0){
        val.giftNum = 1
      }
      if(e > val.residue){
        val.giftNum = val.residue;
        this.$message.warning("赠送数量不可大于优惠券剩余数量")
      }
      this.paySetData.depositCardGifts.map( item => {
        if(item.giftId === val.couponId){
          item.giftNum = val.giftNum
        }
      })
    },

    getProtocol(){ // 获取用户协议内容
      let url = depositCard.getUserProtocol,
          param = { companyId:this.hotelInfo.storeId };
      this.$axios.get(url, param).then( res => {
        if(res.success){
          this.content = res.records.userProtocol
          this.isDepositcard = res.records.isDepositcard || 'NO'
        }else{
          this.$message.warning("获取协议内容失败")
        }
      }).catch( err => {
        this.$message.warning("获取协议内容请求失败")
      })
    },

    saveProtocol(){ // 保存用户协议
      let url = depositCard.editUserProtocol,
          param = {
            userProtocol:this.content,
            isDepositcard:this.isDepositcard,
            companyId:this.hotelInfo.storeId
          };
      this.$axios.post(url,param).then( res => {
        if(res.success){
          this.protocolShow = false;
          this.$message.success("保存成功")
          this.getProtocol()
        }
      })
      this.protocolShow = false;

    },

    originPaySetData(){ // 重置充值设置
      this.paySetData.id=""
      this.paySetData.payAmount = ""
      this.paySetData.donateAmount = ""
      this.paySetData.type="DAILY",
      this.paySetData.sortNo = ""
      this.paySetData.depositCardGifts = []
      this.selectCouponData = []
    }
  },
  watch: {
    hotelInfo(newVal, oldVal) {
        oldVal && newVal.id !== oldVal.id && this.getCardSetList()
    },
    dialogShow(newVal, oldVal){
      if(!newVal){
        this.$refs.paySet.resetFields()
        this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
      }
    }
  },
  filters:{
    cardTypeFilter(val){
      return val === "DAILY" ? "日常" : "开卡"
    },
  }
}
</script>
<style scoped lang="scss">
  /deep/ .v-modal{
    z-index:1109 !important;
  }
</style>
